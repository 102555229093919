<template>
  <div class="store-trace-list">
    <div class="store-trace-list-reporting">
      <div class="store-trace-list-reporting-header">
        <div class="store-trace-list-reporting-header-left">
          <i class="back" @click="goBack"></i>
          <div class="progress">
            <el-progress
              :text-inside="true"
              :stroke-width="15"
              color="#ff6900"
              :percentage="progress"
            >
            </el-progress>
          </div>
        </div>
        <div class="store-trace-list-reporting-header-title">原创存证</div>
        <div
          class="store-trace-list-reporting-header-history"
          @click="toHistory"
        >
          历史存证
        </div>
        <div class="store-trace-list-reporting-header-refresh" @click="refresh">
          <img :src="require('assets/images/refresh-gray.png')" alt="" />
        </div>
      </div>
      <div class="store-trace-list-reporting-reportform">
        <div class="store-trace-list-reporting-reportform-table">
          <el-table :data="reportData" style="width: 1185px; margin: 0 auto">
            <el-table-column prop="" label="序号" width="80">
              <template slot-scope="{ row: { status }, $index: index }">
                <div
                  :class="[
                    'states',
                    status === 1
                      ? 'orange'
                      : status === 0
                      ? 'blue'
                      : status === 2
                      ? 'green'
                      : 'gray',
                  ]"
                >
                  {{
                    status === 1
                      ? "未存证"
                      : status === 0
                      ? "存证中"
                      : status === 2
                      ? "完成"
                      : "失败"
                  }}
                </div>
                <div>{{ index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="filename" label="文件名"></el-table-column>
            <el-table-column width="85"></el-table-column>
            <el-table-column
              prop="fs"
              label="数据大小"
              width="150"
              sortable
            ></el-table-column>
            <el-table-column
              prop="price"
              label="价格"
              width="130"
              sortable
            ></el-table-column>
            <el-table-column label="上传时间" width="180" sortable>
              <template slot-scope="{ row: { createTime } }">
                {{ createTime.split("T")[0] + " " + createTime.split("T")[1] }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="撤销" width="90" align="center">
              <template>
                <span class="revoke"><img :src="require('assets/images/revoke.png')" alt=""/></span>
              </template>
            </el-table-column> -->
            <el-table-column label="状态" width="90" align="center">
              <template slot-scope="scope">
                <span class="status">
                  <img
                    v-if="scope.row.status == 1"
                    :src="require('assets/images/error.png')"
                    alt=""
                  />
                  <img
                    v-else
                    :src="require('assets/images/success.png')"
                    alt=""
                  />
                </span>
              </template>
            </el-table-column>
            <el-table-column width="50"></el-table-column>
            <el-table-column label="操作" width="100">
              <template
                slot-scope="{
                  row: { id, filename, url, provePath, officialProve },
                  $index: i,
                  status,
                }"
              >
                <div class="operate">
                  <span
                    class="zip"
                    v-if="completeStatus(status)"
                    @click="downloadZip(id, filename)"
                  ></span>
                  <span class="report" @click.stop="selectMenu(i)">
                    <div
                      :class="[
                        'submenu',
                        subIndex === i ? 'submenu-active' : '',
                      ]"
                      @click="selectMenuItem"
                    >
                      <span :data-subitem="url">源文件</span>
                      <span :data-subitem="provePath">官方证书</span>
                      <span :data-subitem="officialProve">可信官方证书</span>
                    </div>
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="store-trace-list-reporting-footer">
        <el-pagination
          background
          layout="prev,pager,next"
          prev-text="上一页"
          next-text="下一页"
          :pager-count="5"
          :page-size="pageSize"
          :page-count="pageCount"
          :current-page="pageNum"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { batchEvidenceParse, findEvidenceParseQueue } from "api/store";
import { previewUrl } from "api/common";
export default {
  name: "StoreTraceList",
  data() {
    return {
      //当前页
      pageNum: 1,
      //总页数
      pageCount: 1,
      //页数据条数
      pageSize: 11,
      //报表数据
      reportData: [],
      //进度条
      progress: 0,
      //定时器id
      timer: null,
      //选择的操作菜单id
      subIndex: -1,
    };
  },
  methods: {
    //完成状态
    completeStatus(s) {
      return s == 2;
    },
    //返回
    goBack() {
      this.$bus.$emit("backContentStore");
    },
    //历史存证
    toHistory() {
      this.$router.push({
        path: "/store/storeRightList",
      });
    },
    //刷新
    async refresh() {
      this.$store.commit("playLifeLoading", true);
      await this.findTraceReportQueue();
      this.$store.commit("playLifeLoading", false);
    },
    //当前页改变
    async currentChange(cur) {
      this.pageNum = cur;
      this.$store.commit("playLifeLoading", true);
      await this.findTraceReportQueue();
      this.$store.commit("playLifeLoading", false);
    },
    //下载压缩包
    downloadZip(id, fileName) {
      this.$bus.$emit("zipPackDownload", id, fileName);
    },
    //开始批量查询
    async getReportData() {
      await batchEvidenceParse();
      this.$message({
        type: "success",
        message: "开始查询",
      });
      //开启加载屏
      this.$bus.$emit("loading1", "存证中");
      let last = Date.now();
      this.timer = setInterval(async () => {
        // count++;
        let now = Date.now();
        if (now - last > 1000 * 60 * 5) {
          this.$message({
            type: "error",
            message: "请求超时...",
          });
          clearInterval(this.timer);
          this.$bus.$emit("loading", false);
        }
        await this.findTraceReportQueue();
        this.$bus.$emit("loading", Number(this.progress));
      }, 1500);
    },
    //查询报表数据
    async findTraceReportQueue() {
      const params = {
        page: this.pageNum,
        limit: this.pageSize,
      };
      const res = await findEvidenceParseQueue(params);
      if (res.code == 200) {
        this.reportData = res.data.queue.data;
        this.progress = res.data.percentage
          ? +res.data.percentage
            ? +res.data.percentage
            : 0
          : 0;
        this.pageCount = Math.ceil(res.data.queue.total / this.pageSize);
        if (res.data.percentage == 100) {
          clearInterval(this.timer);
          this.$bus.$emit("loading", false);
        }
      }
    },
    //菜单链接预览
    async locationSubItem(source) {
      const params = {
        url: this.$store.state.onlineBasePath + source,
      };
      this.$store.commit("playLifeLoading", true);
      const res = await previewUrl(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        window.open(res.data);
      }
    },
    //报表操作菜单
    selectMenu(i) {
      if (this.subIndex === i) {
        return (this.subIndex = -1);
      }
      this.subIndex = i;
    },
    //选择菜单项
    selectMenuItem(e) {
      const target = e.target;
      if (target.dataset.subitem && target.dataset.subitem !== "") {
        this.locationSubItem(target.dataset.subitem);
      } else {
        this.$message({
          type: "success",
          message: "暂无记录",
        });
      }
    },
    clickBlankHandler() {
      this.subIndex = -1;
    },
  },
  created() {
    //首次查询一次
    this.$bus.$on("firstFindQueue", async () => {
      await this.findTraceReportQueue();
      const ids = [];
      this.reportData.forEach((i) => ids.push(i.id));
      if (ids.length) {
        this.$bus.$emit("emitStoreFilesParams", ids);
      }
    });
    this.$bus.$on("startFindQueue", () => {
      this.getReportData();
    });
  },
  mounted() {
    document.addEventListener("click", this.clickBlankHandler);
  },
  destroyed() {
    clearInterval(this.timer);
    this.$bus.$off("firstFindQueue");
    this.$bus.$off("startFindQueue");
    document.removeEventListener("click", this.clickBlankHandler);
  },
};
</script>

<style lang="scss" scoped>
.store-trace-list {
  flex: 1;
  &-reporting {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    height: 100%;
    position: relative;
    &-header {
      @include flex-between;
      height: 45px;
      border-bottom: 1px solid #eef0f4;
      &-left {
        @include flex-start;
        margin-left: 20px;
        .back {
          position: relative;
          width: 20px;
          height: 18px;
          margin-right: 15px;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("~assets/images/back-to-editor.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .progress {
          width: 187px;
        }
      }
      &-title {
        font-size: 12px;
        font-weight: 500;
        color: #ff6900;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &-history {
        @include noselect;
        // width: 92px;
        // height: 28px;
        margin-right: 50px;
        // background: #ffffff;
        // box-shadow: 0px 2px 6px 0px rgba(162,170,184,.4);
        // border-radius: 14px;
        // text-align: center;
        // line-height: 28px;
        font-size: 13px;
        font-weight: 500;
        color: #ff6900;
        cursor: pointer;
      }
      &-refresh {
        @include noselect;
        position: absolute;
        top: 16px;
        right: 20px;
        display: inline-block;
        width: 15px;
        height: 14px;
        cursor: pointer;
      }
    }
    &-reportform {
      padding: 0 59px 0 54px;
      flex: 1;
      &-table {
        margin-top: 21px;
        ::v-deep .el-table::before {
          background-color: transparent;
        }
        ::v-deep .el-table {
          overflow: visible;
          .el-table__body-wrapper {
            overflow: visible;
          }
        }
        ::v-deep .el-table td.el-table__cell {
          overflow: hidden;
          &:first-child {
            @include noselect;
            .cell {
              overflow: visible;
            }
          }
          &:last-child {
            overflow: visible;
            .cell {
              overflow: visible;
            }
          }
          border-bottom: none;
          .cell {
            @include ellipsis;
            position: relative;
            .states {
              position: absolute;
              left: 30px;
              top: 50%;
              width: 50px;
              height: 18px;
              margin-top: -9px;
              font-size: 12px;
              font-weight: 800;
              text-align: center;
              line-height: 18px;
              color: #fff;
              z-index: 1;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                z-index: -1;
              }
            }
            .gray {
              &::before {
                background: url("~assets/images/rhombus-gray.png") no-repeat;
              }
              color: #999;
            }
            .green {
              &::before {
                background: url("~assets/images/rhombus-green.png") no-repeat;
              }
            }
            .orange {
              &::before {
                background: url("~assets/images/rhombus-orange.png") no-repeat;
              }
            }
            .blue {
              &::before {
                background: url("~assets/images/rhombus-blue.png") no-repeat;
              }
            }
            .revoke,
            .status {
              @include noselect;
              cursor: pointer;
            }
            .evaluate-wrap {
              @include noselect;
              .original,
              .homology {
                @include flex-start;
                .txt {
                  margin-left: 5px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #ff6900;
                }
              }
              .homology {
                .txt {
                  font-size: 12px;
                  font-weight: 500;
                  &.is-homology {
                    color: #e15240;
                  }
                  &.no-homology {
                    color: #ff6900;
                  }
                }
              }
            }
            .authorize {
              font-size: 12px;
              font-weight: 500;
              text-decoration: underline;
              cursor: pointer;
              &.is-blue {
                color: #ff6900;
              }
              &.is-gray {
                color: #999999;
              }
            }
            .operate {
              display: flex;
              > span {
                width: 16px;
                height: 16px;
                cursor: pointer;
                margin-right: 20px;
              }
              .zip {
                @include backgroundGroup("~assets/images/proof.png");
              }
              .zip:hover {
                @include backgroundGroup("~assets/images/proof-blue.png");
              }
              .report {
                position: relative;
                @include backgroundGroup("~assets/images/store-his-report.png");
                .submenu {
                  @include noselect;
                  &.submenu-active {
                    @include flex-center(column);
                    justify-content: normal;
                    align-items: normal;
                  }
                  display: none;
                  position: absolute;
                  z-index: 1000;
                  top: 20px;
                  left: 5px;
                  width: 99px;
                  height: 102px;
                  padding: 15px 0;
                  background: #ffffff;
                  box-shadow: 0px 4px 8px 1px rgba(31, 34, 40, 0.17);
                  border-radius: 5px;
                  box-sizing: border-box;
                  span {
                    padding: 0 15px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 1;
                    cursor: pointer;
                    &:hover {
                      color: #333333;
                    }
                    &:first-child ~ span {
                      margin-top: 20px;
                    }
                  }
                }
              }
              .report:hover {
                @include backgroundGroup(
                  "~assets/images/store-his-report-blue.png"
                );
              }
            }
          }
        }
        ::v-deep .el-table th.el-table__cell.is-leaf {
          border-bottom: none;
        }
      }
    }
    &-footer {
      @include noselect;
      position: absolute;
      bottom: 21px;
      left: 50%;
      transform: translateX(-50%);
      ::v-deep .el-pagination {
        button {
          width: 80px;
        }
      }
    }
  }
}
</style>
